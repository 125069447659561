import React from "react";
import Marquee from "react-fast-marquee";
import HeyCat from "../assets/lotties/HeyCat";
import { ReactComponent as NavMenu } from "../assets/icons/NavBurger.svg";
import { NavLink } from "react-router-dom";
import { LottieButton } from "./LottieButton";

export const Header = ({ openOverlay }) => {
  return (
    <div className="header padding">
      <Marquee className="message">
        {" "}
        i_ am_ still_ building_ this_ site_ as_ i_ learn_ to_ code_ i_ will_ keep_
        updating_ things_ and_ write_ case_studies_ in_
        more_ detail_ ....ありがとうございます Thankyou_ for_ the_ visit_....
      </Marquee>
      <div className="logo">
        <NavLink to="/">UXKEO_</NavLink>
      </div>
      <div className="nav-menu">
        <LottieButton
          Icon={<NavMenu />}
          lottie={HeyCat}
          onclick={openOverlay}
        />
      </div>
    </div>
  );
};
