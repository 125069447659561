import React from "react";

export const Grids = () => {
  return (
    <div className="grids-container">
      {Array.from({length:18}).map((_, rowIndex)=>(
        <div className="row" key={rowIndex}>
            {Array.from({length:30}).map((_, boxIndex)=>(
                <div className="box" key={boxIndex}></div>
            ))}
        </div>
      ))}
    </div>
  );
};
