import React from "react";
import { LottieButton } from "../../components/LottieButton";
import CatLeft from "../../assets/lotties/CatLeft";
import { ReactComponent as ArrowLeft } from "../../assets/icons/ArrowLeft.svg";
import { Grids } from "../../components/Grids";

export const EVtrical = () => {
  return (
    <>
      <section className="section cover-section">
        <div className="cover">
          <img
            src="https://images.unsplash.com/photo-1586023492125-27b2c045efd7?w=2000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZGVjb3J8ZW58MHwwfDB8fHww"
            alt=""
          />
          <h1>EVtrical_ </h1>
          <div className="close-arrow">
            <LottieButton Icon={<ArrowLeft />} lottie={CatLeft} />
          </div>
        </div>
        <Grids/>
      </section>
      <section className="section padding details">
        <div className="details-container">
          <div className="title-container">
            <h1 className="title">
              Stylish, Functional and Beautifully Crafted Ceramic Decors For You
              Home_
            </h1>
            <p className="description">
            BERR Decor is a brand dedicated to creating elegant ceramic vases, stunning wall art, and vibrant floral arrangements, transforming living spaces into canvases of timeless beauty and artistic inspiration.
            </p>
          </div>
          <div className="status-container">
            <a href="https://www.google.com" className="site-link">
              Archived Site_
            </a>
            <div className="bento">
              <div className="left">
                <div className="top">
                  <div className="year">
                    <h3>Year_</h3>
                    <p>2024_</p>
                  </div>
                  <div className="status">
                    <h3>Status_</h3>
                    <p>Completed_</p>
                  </div>
                </div>
                <div className="bottom">
                  <h3>Roles_</h3>
                  <p>
                    WebUIDesign_, Ecommerce Best Practises_, Responsiveness_
                  </p>
                </div>
              </div>
              <div className="logo">
                <img
                  src="https://images.unsplash.com/photo-1586023492125-27b2c045efd7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZGVjb3J8ZW58MHwwfDB8fHww"
                  alt="Logo"
                />
              </div>
            </div>
          </div>
        </div>
        <Grids />
      </section>
      <section className="section padding problem-statement">
        <div className="problem-container">
          <h3>Desired Outcome_</h3>
          <p>
            The primary objective is to create a visually captivating and
            user-friendly platform that effectively showcase the brands
            exquisite range of ceramic vases, handmade wall art, and dried
            floweer arrangements. The website should reflect the brands
            commitment to infusing art into everyday living by offering an
            intuitive and seamless shopping experience_
          </p>
          <div className="outcomes">
            <ul>
              <li>Enhanced User Experience_</li>
              <li>Comprehensive Product Showcasing_</li>
              <li>Brand storytelling with visually stunning UI_</li>
            </ul>
            <ul>
              <li>Mobile Responsiveness_</li>
              <li>Seamless E-commerce functionality_</li>
              <li>Customer Engagement and support_</li>
            </ul>
          </div>
          <div className="mockup">
          <img
            src="https://images.unsplash.com/photo-1586023492125-27b2c045efd7?w=2800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZGVjb3J8ZW58MHwwfDB8fHww"
            alt=""
          />
          <img
            src="https://images.unsplash.com/photo-1586023492125-27b2c045efd7?w=2800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZGVjb3J8ZW58MHwwfDB8fHww"
            alt=""
          />
          <img
            src="https://images.unsplash.com/photo-1586023492125-27b2c045efd7?w=2800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZGVjb3J8ZW58MHwwfDB8fHww"
            alt=""
          />
          </div>
        </div>
        <Grids/>
      </section>
      <section className="section  prototype-section">
        <video src="https://videos.pexels.com/video-files/3770033/3770033-sd_640_360_25fps.mp4" autoPlay controls></video>
      </section>
    </>
  );
};