import Gdiot1 from "../assets/imgs/gdiot1.png";
import Gdiot2 from "../assets/imgs/gdiot2.jpg";
import Marshal1 from "../assets/imgs/marshal1.jpg";
import Marshal2 from "../assets/imgs/marshal2.jpg";

const cases = [
  {
    id: 1,
    title: "Astrotalk_",
    description:
      "Astrotalk is the best astrology website for online Astrology predictions. With a gigantic pool of 13000+ Vedic astrologers, Tarot readers, Numerologists, Vastu experts and more.",
    tags: {
      t1: "Redesign_",
      t2: "Business_",
      t3: "Conversion_",
    },
    images: {
      img1: "https://images.unsplash.com/photo-1637757935037-a7837f36807d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      img2: "https://images.unsplash.com/photo-1640091777650-5706c3180d72?q=80&w=2056&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    siteLink:
      "https://medium.com/@UXKEO/in-depth-ux-case-study-redesigning-astrotalk-s-listing-page-7a7bcc6e2df3",
    openLink: "/astrotalk",
  },
  {
    id: 2,
    title: "GDIOT_",
    description:
      "GDIOT is an experiential Edtech Startup that started with a Vision to take students from absolute 0 to 1 and is dedicated to the overall development of students.",
    tags: {
      t1: "Product_",
      t2: "Internship_",
      t3: "Startup_",
    },
    images: {
      img1: Gdiot1,
      img2: Gdiot2,
    },
    siteLink:
      "https://www.behance.net/gallery/194595763/1st-UX-Internship-case-study",
    openLink: "/gdiot",
  },
  {
    id: 3,
    title: "BerrDecor_",
    description:
      "In 2023, a team of creative minds united to bring BERR to life, a brand dedicated to crafting exquisite ceramic vases, stunning wall art, and enchanting flora arrangements.",
    tags: {
      t1: "UI Design_",
      t2: "Ecommerce_",
      t3: "DevAgency_",
    },
    images: {
      img1: "https://images.unsplash.com/photo-1586023492125-27b2c045efd7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZGVjb3J8ZW58MHwwfDB8fHww",
      img2: "https://images.unsplash.com/photo-1463320898484-cdee8141c787?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGRlY29yfGVufDB8MHwwfHx8MA%3D%3D",
    },
    siteLink: "https://www.medium.com/@UXKEO",
    openLink: "/berrdecor",
  },
  {
    id: 4,
    title: "Marshall`s Cafe_",
    description:
      "Marshall`s Book Cafe tends to the matters of mind, soul and heart of readers, thinkers, scholars, people who are always on the lookout of a quiet and serene place to relax and work.",
    tags: {
      t1: "Google UX_",
      t2: "Foundations_",
      t3: "Project_",
    },
    images: {
      img1: Marshal2,
      img2: Marshal1,
    },
    siteLink: "https://www.medium.com/@UXKEO",
    openLink: "/marshallscafe",
  },
  {
    id: 5,
    title: "EVtrical_",
    description:
      "EVtrical a startup of young, with a vision to accelerate the adoption of Eco-friendly technologies & solutions, to contribute towards a cleaner and greener world.",
    tags: {
      t1: "WebDesign_",
      t2: "Freelance_",
      t3: "React_",
    },
    images: {
      img1: "https://images.unsplash.com/photo-1594535182308-8ffefbb661e1?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      img2: "https://images.unsplash.com/photo-1532601224476-15c79f2f7a51?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    siteLink: "https://www.medium.com/@UXKEO",
    openLink: "/evtrical",
  },

  {
    id: 6,
    title: "Botanica_",
    description:
      "Botanica: a plant nursery, e-commerce platform, and plant care guide provider. Discover diverse plants, shop gardening essentials, and access expert care tutorials.",
    tags: {
      t1: "WebApp_",
      t2: "Project_",
      t3: "FigmaPro_",
    },
    images: {
      img1: "https://images.unsplash.com/photo-1598902468171-0f50e32a7bf2?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      img2: "https://images.unsplash.com/photo-1462530348824-65844534c0ed?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    siteLink: "https://www.medium.com/@UXKEO",
    openLink: "/botanica",
  },
];

export default cases;
