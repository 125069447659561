import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../assets/icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/icons/ArrowRight.svg";
import { ReactComponent as ButtonArrow } from "../assets/icons/ButtonArrow.svg";
import CatLeft from "../assets/lotties/CatLeft";
import CatRight from "../assets/lotties/CatRight";
import { Lines } from "../components/Lines";
import { Grids } from "../components/Grids";
import { LottieButton } from "../components/LottieButton";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import cases from "../data/casesData";

gsap.registerPlugin(ScrollTrigger);

export const CaseStudies = () => {
  const casesRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    const casesWrapper = casesRef.current;
    const marker = markerRef.current;

    gsap.to(casesWrapper, {
      xPercent: -101 * (cases.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".case-studies",
        start: "top top",
        pin: true,
        scrub: 1,
        snap: 1 / (cases.length - 1),
        end: () => `+=${casesWrapper.scrollWidth - window.innerWidth}`,
        onUpdate: (self) => {
          const progress = self.progress.toFixed(2);
          const caseIndex = Math.round(progress * (cases.length - 1));
          marker.textContent = caseIndex.toString().padStart(2, "0");
        },
      },
    });
  }, []);

  const handleScrollUp = () => {
    const service = document.querySelector("#home");
    if (service) {
      service.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const handleScrollDown = () => {
    const service = document.querySelector("#service");
    if (service) {
      service.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <section id="caseStudies" className="section padding case-studies">
        <div className="case-study-container">
          <div className="case-study-title">
            <div className="title">Case studies_</div>
            <div className="case-study-arrows">
              <div className="marker-wrapper">
                <span ref={markerRef}> 00 </span>
              </div>
              <div className="arrow">
                <LottieButton
                  Icon={<ArrowLeft />}
                  lottie={CatLeft}
                  onclick={handleScrollUp}
                />
                <LottieButton
                  Icon={<ArrowRight />}
                  lottie={CatRight}
                  onclick={handleScrollDown}
                />
              </div>
            </div>
          </div>
          <div className="cases-container" ref={casesRef}>
            {cases.map((caseStudy) => (
              <div key={caseStudy.id} className="cases-wrapper">
                <div className="case-study-details">
                  <div className="cases-title">{caseStudy.title}</div>
                  <div className="cases-description">
                    {caseStudy.description}
                  </div>
                  <div className="cases-tags">
                    <span>Tags_</span>
                    <ul>
                      <li>{caseStudy.tags.t1}</li>
                      <li>{caseStudy.tags.t2}</li>
                      <li>{caseStudy.tags.t3}</li>
                    </ul>
                  </div>
                  <div className="cases-buttons">
                    <a
                      href={caseStudy.siteLink}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="btn open-study"
                    >
                      case study_
                      <ButtonArrow />
                    </a>
                    <Link to="/" className="btn visit-site">
                      disabled_
                      <ButtonArrow />
                    </Link>
                  </div>
                </div>
                <div className="case-study-images">
                  <img src={caseStudy.images.img1} alt={caseStudy.title} />
                  <img src={caseStudy.images.img2} alt={caseStudy.title} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <Lines HLine={3} VLine={4} />
        <Grids />
      </section>
    </>
  );
};
