import React from "react";
import { LottieButton } from "../../components/LottieButton";
import CatLeft from "../../assets/lotties/CatLeft";
import { ReactComponent as ArrowClose } from "../../assets/icons/ArrowClose.svg";
import { Grids } from "../../components/Grids";

export const BerrDecor = () => {
  return (
    <main>
      <section className="padding cover-section">
        <div className="cover-container">
          <div className="cover-title">
            <h1 className="title">BerrDecor_</h1>
            <p></p>
          </div>
        </div>
        <div className="close-arrow">
          <LottieButton Icon={<ArrowClose/>} lottie={CatLeft}/>
        </div>
        <Grids/>
      </section>
    </main>
  );
};
