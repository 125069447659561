import React, { useState, useEffect } from "react";
import { Grids } from "../components/Grids";
import { Lines } from "../components/Lines";
import { LottieButton } from "../components/LottieButton";
import { ReactComponent as ArrowDown } from "../assets/icons/ArrowDown.svg";
import BadCat from "../assets/lotties/BadCat";
import gsap from "gsap";
import TextPlugin from "gsap/TextPlugin";
import Typewriter from "../assets/audios/keyboardAudio.mp3";
import { IntroOverlay } from "../components/IntroOverlay";
import { CaseStudies } from "./CaseStudies";
import Service from "./Service";
import Social from "./Social";
import { Footer } from "./Footer";

gsap.registerPlugin(TextPlugin);

let tl = gsap.timeline();

const homeAnimation = (completeAnimation) => {
  const audio = new Audio(Typewriter);
  gsap.set(".header", {
    opacity: 0,
  });

  tl.from(".banner .name-container .name", {
    duration: 2.8,
    y: 980,
    ease: "expo.inOut",
    skewX: 24,
    stagger: {
      amount: 0.4,
    },
  })
    .to(".overlay-top", {
      duration: 1.4,
      height: 0,
      ease: "expo.inOut",
      stagger: 0.4,
    })
    .to(".name-container .tag", {
      onStart: () => {
        audio.play();
      },
      duration: 4,
      display: "inline",
      text: {
        value: "A self-taught designeer_",
      },
      ease: "power2.inOut",
      onComplete: () => {
        audio.pause();
      },
    })
    .to(".header", {
      duration: 1.2,
      opacity: 1,
      ease: "power4.inOut",
    })
    .to(".home-arrow", {
      duration: 1,
      opacity: 1,
      ease: "power4.inOut",
    })
    .fromTo(
      ".home-section .horizontal-line",
      {
        strokeDasharray: 1920,
        strokeDashoffset: 1920,
      },
      {
        strokeDashoffset: 0,
        duration: 1,
        ease: "power4.inOut",
      }
    )
    .fromTo(
      ".home-section .vertical-line",
      {
        strokeDasharray: 1080,
        strokeDashoffset: 1080,
      },
      {
        strokeDashoffset: 0,
        duration: 1,
        ease: "power4.inOut",
        onComplete: completeAnimation,
      }
    );
};

const Home = () => {
  gsap.to("body", { duration: 0, css: { visibility: "visible" } });

  const [animationComplete, setAnimationComplete] = useState(false);

  const completeAnimation = () => {
    setAnimationComplete(true);
  };

  useEffect(() => {
    homeAnimation(completeAnimation);
  }, []);

  const handleScroll = () => {
    const caseStudies = document.querySelector("#caseStudies");
    if (caseStudies) {
      caseStudies.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <section id="home" className="section padding home-section">
        {animationComplete === false ? <IntroOverlay /> : ""}
        <div className="banner-container">
          <div className="banner">
            <div className="name-container">
              <span className="name">Vinay</span>
              <span className="name">Choudhari_</span>
              <span className="tag"> _______________________ </span>
            </div>
            <div className="home-arrow" onClick={handleScroll}>
              <LottieButton Icon={<ArrowDown />} lottie={BadCat} />
            </div>
          </div>
        </div>
        <Lines HLine={4} VLine={5} />
        <Grids />
      </section>
      <CaseStudies/>
      <Service />
      <Social />
      <Footer />
    </>
  );
};

export default Home;
