import React from 'react'
import {ReactComponent as HorizontalLine} from '../assets/HorizontlLine.svg'
import {ReactComponent as VerticalLine} from '../assets/VerticalLine.svg'


export const Lines = ({HLine, VLine}) => {
  return (
    <div className='lines-container'>
        <div className="horizontal-container">
            {Array.from({length:HLine}).map((_, index)=>(
                <HorizontalLine key={index}/>
            ))}
        </div>
        <div className="vertical-container">
            {Array.from({length:VLine}).map((_, index)=>(
                <VerticalLine key={index}/>
            ))}
        </div>
    </div>
  )
}
