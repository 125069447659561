import React from 'react'

export const IntroOverlay = () => {
  return (
    <>
      <div className="intro-overlay">
        <div className="overlay-top"></div>
      </div>
    </>
  )
}