import axios from 'axios';
import UAParser from 'ua-parser-js';

export const getUserInfo = async () => {
  try {
    // Fetch IP address
    const ipResponse = await axios.get('https://api.ipify.org?format=json');
    const ip = ipResponse.data.ip;

    // Fetch detailed location information using ipinfo.io
    const locationResponse = await axios.get(`https://ipinfo.io/${ip}/json`);
    const { country } = locationResponse.data; // Use country_name for full country name

    // Get browser information using ua-parser-js
    const parser = new UAParser();
    const browser = parser.getBrowser().name;

    return { ip, country, browser };
  } catch (error) {
    console.error('Error fetching user info:', error);
    return { ip: 'unknown', country: 'country', browser: 'unknown' };
  }
};
