import React, { useEffect, useRef } from "react";
import { LottieButton } from "../components/LottieButton";
import { ReactComponent as ArrowUp } from "../assets/icons/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "../assets/icons/ArrowDown.svg";
import ItsCat from "../assets/lotties/ItsCat";
import LazyCat from "../assets/lotties/LazyCat";
import { Lines } from "../components/Lines";
import { Grids } from "../components/Grids";
import Build from "../assets/imgs/Build.jpg";
import Work from "../assets/imgs/work.jpg";
import Dream from "../assets/imgs/dream.jpg";
import Create from "../assets/imgs/create.jpg";

const Service = () => {
  const serviceSectionRef = useRef(null);
  const tagsContainerRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const percent = e.clientX / window.innerWidth;
      tagsContainerRef.current.animate(
        {
          transform: `translateX(${
            percent * tagsContainerRef.current.offsetWidth * -1
          }px)`,
        },
        {
          fill: "forwards",
          duration: 4000,
        }
      );
    };

    const handleMouseEnter = () => {
      window.addEventListener("mousemove", handleMouseMove);
    };

    const handleMouseLeave = () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };

    const serviceSection = serviceSectionRef.current;
    serviceSection.addEventListener("mouseenter", handleMouseEnter);
    serviceSection.addEventListener("mouseleave", handleMouseLeave);
  });

  const handleScrollUp = () => {
    const service = document.querySelector("#caseStudies");
    if (service) {
      service.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const handleScrollDown = () => {
    const service = document.querySelector("#social");
    if (service) {
      service.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <section
      id="service"
      ref={serviceSectionRef}
      className="section service-section padding"
    >
      <div className="service-container">
        <div className="title-container">
          <span className="service-title">#BE OF SERVICE</span>
          <div className="tags-container" ref={tagsContainerRef}>
            <div className="service-tags">
              <span>Build_</span>
              <div className="img-wrapper">
                <img src={Build} alt="" />
              </div>
            </div>
            <div className="service-tags">
              <span>Work_</span>
              <div className="img-wrapper">
                <img src={Work} alt="" />
              </div>
            </div>
            <div className="service-tags">
              <span>Dream_</span>
              <div className="img-wrapper">
                <img src={Dream} alt="" />
              </div>
            </div>
            <div className="service-tags">
              <span>Create_</span>
              <div className="img-wrapper">
                <img src={Create} alt="" />
              </div>
            </div>
            <div className="service-tags">
              <span>Build_</span>
              <div className="img-wrapper">
                <img src={Build} alt="" />
              </div>
            </div>
            <div className="service-tags">
              <span>Work_</span>
              <div className="img-wrapper">
                <img src={Work} alt="" />
              </div>
            </div>
            <div className="service-tags">
              <span>Dream_</span>
              <div className="img-wrapper">
                <img src={Dream} alt="" />
              </div>
            </div>
            <div className="service-tags">
              <span>Create_</span>
              <div className="img-wrapper">
                <img src={Create} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-arrows">
          <LottieButton
            Icon={<ArrowUp />}
            lottie={ItsCat}
            onclick={handleScrollUp}
          />
          <LottieButton
            Icon={<ArrowDown />}
            lottie={LazyCat}
            onclick={handleScrollDown}
          />
        </div>
      </div>
      <Lines HLine={4} VLine={3} />
      <Grids />
    </section>
  );
};

export default Service;
