import React, { useRef, useEffect, useState } from "react";
import { LottieButton } from "../components/LottieButton";
import { ReactComponent as ArrowUp } from "../assets/icons/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "../assets/icons/ArrowDown.svg";
import BadCat from "../assets/lotties/BadCat";
import { importAll } from "../utils/importImages";
import { getUserInfo } from "../utils/getUserInfo";
import { Lines } from "../components/Lines";
import { Grids } from "../components/Grids";
import { Footer } from "./Footer";

const aiImages = importAll(
  require.context("../assets/imgs/ai", false, /\.(png|jpe?g|svg)$/)
);

const DumpYard = () => {
  const galleryRef = useRef(null);
  const [userInfo, setUserInfo] = useState({
    ip: "",
    country: "",
    browser: "",
  });
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const info = await getUserInfo();
      setUserInfo(info);
    };

    fetchUserInfo();
  }, []);

  const handleMouseOver = () => {
    if (galleryRef.current) {
      galleryRef.current.classList.add("paused");
    }
  };

  const handleMouseLeave = () => {
    if (galleryRef.current) {
      galleryRef.current.classList.remove("paused");
    }
  };

  const handleScrollUp = () => {
    const service = document.querySelector("#home");
    if (service) {
      service.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const handleScrollDown = () => {
    const service = document.querySelector("#footer");
    if (service) {
      service.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const ScrollDown = () => {
    const service = document.querySelector("#soon");
    if (service) {
      service.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <section id="home" className="section padding dump-section">
        <div className="dump-yard">
          <div className="dump-title">
            <span className="title">"Dump yard_</span>
            <p className="tag">
              _____ this page is way behind of its development time_
            </p>
          </div>
          <div className="tagline">
            <div className="left">
              <span className="left-tag">
                "a day in a life of a<br /> ___kinda stuff
              </span>
              <div
                className="ai-gallery"
                ref={galleryRef}
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
              >
                <div className="scrolling-wrapper">
                  {Object.keys(aiImages).map((key, index) => (
                    <img key={index} src={aiImages[key]} alt="AI Generated" />
                  ))}
                  {Object.keys(aiImages).map((key, index) => (
                    <img
                      key={index + Object.keys(aiImages).length}
                      src={aiImages[key]}
                      alt="AI Generated"
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="right">
              <span
                className="right-tag"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                "Hello_ {userInfo.ip} <br /> from {userInfo.country}_, on{" "}
                {userInfo.browser}_"
                {showTooltip && (
                  <p className="tooltip">
                    no computers_ were harmed_ in fetching this data_.
                    "greeting_ purpose only_ <br /> こんにちは
                  </p>
                )}
              </span>
              <div className="dump-arrow">
                <LottieButton
                  Icon={<ArrowDown />}
                  lottie={BadCat}
                  onclick={ScrollDown}
                />
              </div>
            </div>
          </div>
        </div>
        <Lines HLine={2} VLine={2} />
        <Grids />
      </section>
      <section id="soon" className="section padding dump-header">
        <div className="soon">
          <span>
            {" "}
            <b> dedicating_ this_ page_ as_ my_ digital_ diary_ </b> Lorem ipsum
            dolor sit amet consectetur.{" "}
            <b> planning_ to_ make_ public_ soon_ </b> Lorem ipsum{" "}
            <b> Lots_ of_ coooooooool_ stuff_</b> dolor sit.{" "}
            <b> its all about my journey so far_ </b> Lorem, ipsum.{" "}
            <b>
              {" "}
              want_ to_ be_ a part_ of it_ stay_ connected_ on_{" "}
              <a href="https://www.instagram.com/uxkeo_">@instagram_</a>{" "}
            </b>
          </span>
          <p> &#123; visibility: hidden_ &#125; </p>
          <p> &#123; isAvailable(False) = ((when)) =&gt; soon_ &#125; </p>
          <div className="soon-arrow">
            <LottieButton
              Icon={<ArrowUp />}
              lottie={BadCat}
              onclick={handleScrollUp}
            />
            <LottieButton
              Icon={<ArrowDown />}
              lottie={BadCat}
              onclick={handleScrollDown}
            />
          </div>
        </div>
        <Lines HLine={3} VLine={3} />
        <Grids />
      </section>
      <Footer />
    </>
  );
};

export default DumpYard;
