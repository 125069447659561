import React from "react";
import "./styles/App.scss";
import Home from "./pages/Home";
import AnimatedCursor from "react-animated-cursor";
import { Route, Routes, useLocation } from "react-router-dom";
import gsap from "gsap";
import { Header } from "./components/Header";
import OverlayNavigation from "./pages/OverlayNavigation";
import About from "./pages/About";
import DumpYard from "./pages/DumpYard";
import { BerrDecor } from "./pages/cases/BerrDecor";
import { Astrotalk } from "./pages/cases/Astrotalk";
import { MarshallsCafe } from "./pages/cases/MarshallsCafe";
import { EVtrical } from "./pages/cases/EVtrical";
import { Gdiot } from "./pages/cases/Gdiot";
import { Botanica } from "./pages/cases/Botanica";

function App() {
  const location = useLocation();

  const openOverlay = () => {
    gsap.to(".overlay", {
      x: "0%",
      y: "0%",
      duration: 1.5,
      ease: "power4.inOut",
    });
  };

  const closeOverlay = () => {
    gsap.to(".overlay", {
      x: "0%",
      y: "-100%",
      duration: 1.5,
      ease: "power4.inOut",
      onComplete: () => {
        gsap.set(".overlay", { x: "-100%", y: "0%" });
      },
    });
  };

  // Check if current path is DumpYard or any case study route
  const isExcludedRoute = [
    "/dump-yard",
    "/berrdecor",
    "/astrotalk",
    "/marshallscafe",
    "/evtrical",
    "/gdiot",
    "/botanica",
  ].includes(location.pathname);

  return (
    <div className="App">
      <AnimatedCursor
        outerStyle={{
          mixBlendMode: "difference",
          backgroundColor: "#e85a58",
          zIndex: 10000,
        }}
        innerStyle={{
          mixBlendMode: "difference",
          backgroundColor: "#f6e7ca",
          zIndex: 10000,
        }}
      />
      {/* Render Header and OverlayNavigation only if not in excluded routes */}
      {!isExcludedRoute && (
        <>
          <Header openOverlay={openOverlay} />
          <OverlayNavigation closeOverlay={closeOverlay} />
        </>
      )}
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/dump-yard" element={<DumpYard />} />
        <Route path="/berrdecor" element={<BerrDecor />} />
        <Route path="/astrotalk" element={<Astrotalk />} />
        <Route path="/marshallscafe" element={<MarshallsCafe />} />
        <Route path="/evtrical" element={<EVtrical />} />
        <Route path="/gdiot" element={<Gdiot />} />
        <Route path="/botanica" element={<Botanica />} />
      </Routes>
    </div>
  );
}

export default React.memo(App);
