import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as NavMenuClose } from "../assets/icons/NavArrowUp.svg";
import { ReactComponent as InputArrow } from "../assets/icons/InputArrow.svg";
import Beowtiful from "../assets/lotties/Beowtiful";
import CatUp from "../assets/lotties/CatUp";
import meow from "../assets/audios/meow.mp3";
import { LottieButton } from "../components/LottieButton";
import { Lines } from "../components/Lines";
import { Grids } from "../components/Grids";
import gsap from "gsap";

const OverlayNavigation = ({ closeOverlay }) => {
  const [audio] = useState(new Audio(meow));
  const handleMouseEnter = () => {
    audio.play();
  };
  useEffect(() => {
    const overlay = document.querySelector(".overlay-section");

    gsap.set(overlay, { x: "-100%", y: "0%" });

    function openOverlay() {
      gsap.to(overlay, {
        x: "0%",
        y: "0%",
        duration: 1.5,
        ease: "power4.inOut",
        stagger: {
          duration: 1.5,
        },
      });
    }

    function closeOverlay() {
      gsap.to(overlay, {
        x: "0%",
        y: "-100%",
        duration: 1.5,
        ease: "power4.inOut",
        stagger: {
          duration: 1.5,
        },
        onComplete: function () {
          gsap.set(overlay, { x: "-100%", y: "0%" });
        },
      });
    }

    document.querySelector(".nav-menu").addEventListener("click", openOverlay);
    document
      .querySelector(".menu-icon-close")
      .addEventListener("click", closeOverlay);
  });

  return (
    <section className="overlay-section padding">
      <div className="overlay">
        <div className="overlay-menu">
          <nav className="nav">
            <div className="logo">
              <NavLink to="/">UXKEO_</NavLink>
            </div>
            <div className="menu-icon-close">
              <LottieButton
                Icon={<NavMenuClose />}
                lottie={CatUp}
                onclick={closeOverlay}
              />
            </div>
          </nav>
          <ul>
            <li>
              <a href="/" className="number">
                00
              </a>
              <NavLink to="/" className="text">
                Home_
              </NavLink>
            </li>
            <li>
              <a href="/" className="number">
                01
              </a>
              <NavLink to="/about" className="text">
                About me_
              </NavLink>
            </li>
            <li>
              <a href="/" className="number">
                02
              </a>
              <NavLink to="/dump-yard" className="text">
                Dump yard_
              </NavLink>
            </li>
          </ul>
          <div className="overlay-footer">
            <div className="footer-tag" onMouseEnter={handleMouseEnter}>
              Ahhh.... Isnt Life Beowwtiful__
            </div>
            <div className="input">
              <input
                id="input-data"
                type="text"
                placeholder="Hmmm... what are you thinking today_"
              />
              <div className="input-arrow">
                <LottieButton Icon={<InputArrow />} lottie={Beowtiful} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Lines HLine={3} VLine={3} />
      <Grids />
    </section>
  );
};

export default OverlayNavigation;
