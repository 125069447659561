import React, { useEffect } from "react";
import gsap from "gsap";
import hoverEffect from "hover-effect";
import { Footer } from "./Footer";
import { Lines } from "../components/Lines";
import { Grids } from "../components/Grids";
import My from "../assets/imgs/My.jpg";
import Designeer from "../assets/imgs/Designeer.jpg";
import Developeer from "../assets/imgs/Developer.jpg";
import Journeey from "../assets/imgs/Journeey.jpg";
import profileImage1 from "../assets/imgs/keo.png";
import profileImage2 from "../assets/imgs/keo2.png";
import distortImage from "../assets/imgs/distort.png";
import Brain from "../assets/gifs/brain.gif";
import Bath from "../assets/gifs/bath.gif";
import Code from "../assets/gifs/code.gif";
import Design from "../assets/gifs/design.gif";
import Idea from "../assets/gifs/idea.gif";

const About = () => {
  useEffect(() => {
    new hoverEffect({
      parent: document.querySelector(".profile-image"),
      intensity1: 0.1,
      intensity2: 0.1,
      angle2: Math.PI / 2,
      image1: profileImage1,
      image2: profileImage2,
      displacementImage: distortImage,
    });

    let tl = gsap.timeline({ repeat: -1 });

    const words = [" Designeer_", " Developeer_", " Marwaadi_"];

    words.forEach((word) => {
      let typewritterText = gsap.timeline({
        repeat: 1,
        yoyo: true,
      });

      typewritterText.to(".typewriter-text", {
        duration: 1.4,
        text: word,
      });

      tl.add(typewritterText);
    });
  });
  return (
    <>
      <section id="home" className="section about-section">
        <div className="container">
          <div className="left">
            <div className="top">
              <div className="gif">
                <img src={Brain} alt="Repairing Brain" />
              </div>
              <div className="quote">
                <span>
                  "Just some tiny repairs here and there and we are good to go_"
                </span>
              </div>
            </div>
            <div className="bottom">
              <span>bainstorming_</span>
            </div>
          </div>
          <div className="right">
            <span className="title">tHIS pAGE iS uNDER iDEATION_</span>
            <span className="tag">
              "something_ something_ something_ goes_ here_ god_ knows_ what_
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Provident, amet_"
            </span>
            <span className="quote">
              Keep scrolling_ you might find something about me 👀
            </span>
          </div>
        </div>
        <Lines HLine={3} VLine={4} />
        <Grids />
      </section>
      <section className="section about-banner">
        <div className="banner-container">
          <div className="middle">
            <div className="middle-container">
              <span>My_</span>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Guess_
                will_ have_ to_ wait_ for_ it_ "probably_ in DUMPYARD_ or
                comming_ soon_ on YOUTUBE_
              </p>
              <img src={My} alt="" />
            </div>
            <div className="middle-container">
              <span>Designeer_</span>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Guess_
                will_ have_ to_ wait_ for_ it_ "probably_ in DUMPYARD_ or
                comming_ soon_ on YOUTUBE_
              </p>
              <img src={Designeer} alt="" />
            </div>
            <div className="middle-container">
              <span>Developeer_</span>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Guess_
                will_ have_ to_ wait_ for_ it_ "probably_ in DUMPYARD_ or
                comming_ soon_ on YOUTUBE_
              </p>
              <img src={Developeer} alt="" />
            </div>
            <div className="middle-container">
              <span>Journeey_</span>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Guess_
                will_ have_ to_ wait_ for_ it_ "probably_ in DUMPYARD_ or
                comming_ soon_ on YOUTUBE_
              </p>
              <img src={Journeey} alt="" />
            </div>
          </div>
          <div className="top">
            <span>_About me</span>
          </div>
          <div className="bottom">
            <div className="left">
              <span className="about-tag">
                I am
                <span className="typewriter-text"></span>
              </span>
              <span className="about-description">
                :Because i Build, Work, Dream, Create, and Disrupt for a living,
                and when i see problems around this world, i try to SOLVe them_
              </span>
            </div>
            <div className="right">hMMM... nOW wHAT tO pUT oVER hERE_</div>
          </div>
        </div>
        <Grids />
      </section>
      <section className="section image-section">
        <div className="left-container">
          <div className="expected">
            <p>
              HuH! another empty area_ <br /> man_ i hAVE lots of work to do_
              <br /> bETTER_ Leave_ it eMPTY_ <br />
              essshhhhhh......... 😬
            </p>
          </div>
          <div className="qualities">
            <span className="title">EXPLOREER_</span>
            <span className="title">Visionary_</span>
            <span className="title">TECHIEE_</span>
            <span className="title">&LAZZZzZY_</span>
          </div>
        </div>
        <div className="container">
          <div className="top">
            <div className="left">
              <img src={Bath} alt="" />
            </div>
            <div className="right">
              <span>Demographics_</span>
              <div className="demographics-container">
                <div className="demo-left">
                  <p>Height_ 5'9</p>
                  <p>Weight_ 60</p>
                </div>
                <div className="demo-right">
                  <p>Age_ 22</p>
                  <p>Education_ (CS Self-taught)</p>
                </div>
              </div>
            </div>
          </div>
          <div className="middle">
            <div
              className="middle-container"
              style={{
                borderRight: "2px solid #290e0e",
              }}
            >
              <img src={Code} alt="" />
            </div>
            <div className="profile-image"></div>
            <div
              className="middle-container"
              style={{
                borderLeft: "2px solid #290e0e",
              }}
            >
              <img src={Design} alt="" />
            </div>
          </div>
          <div className="bottom">
            <div className="left">
              <span>Experience_</span>
              <div>
                <p>2024_ Freelance_ @VarientSpark @BerrDecor</p>
                <p>2023_ Contract_ @EVtrical @SaharaAI</p>
                <p>2022_ Internship_ @GDIOT Innovations</p>
              </div>
            </div>
            <div className="right">
              <img src={Idea} alt="" />
            </div>
          </div>
        </div>
        <Grids />
      </section>
      <section className="section hire-section">
        <div className="hire-me">
          <div className="hire-left">
            have a great idea? <br /> count me in_
          </div>
          <div className="hire-right">
            <div className="hire-middle">
              <div>startups_</div>
              <div>tech_</div>
              <div>product_</div>
              <div>sass_</div>
              <div>open to remote_</div>
            </div>
            <div className="hire-bottom">
              <a
                href="https://api.whatsapp.com/send?phone=917499188832&text=Hello! KEO_ we are building something great @"
                target="_blank"
                rel="noreferrer noopener"
              >
                so where do we start_
              </a>
            </div>
          </div>
        </div>
        <Grids />
      </section>
      <section className="section empty-section padding">
        <div className="empty">
          <p>woooow_</p>
          <span>
            JUST_ LOOK_ AT_ THIS_ EMPTY_ BEAUTIFUL_ PAGE_ Lorem ipsum dolor sit.
            GIVING_ OUT_ LOTS_ AND_ LOTS_ OF_ INSPIRATION_ Lorem ipsum dolor
            sit. THERES_ SOO_ MUCH_ GOING_ ON_ IN_ MY_ MIND......
          </span>
        </div>
        <Lines HLine={1} VLine={2} />
        <Grids />
      </section>
      <Footer />
    </>
  );
};

export default About;
