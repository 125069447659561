import React, { useRef, useEffect } from "react";
import { LottieButton } from "../components/LottieButton";
import { ReactComponent as ArrowUp } from "../assets/icons/ArrowUp.svg";
import BadCat from "../assets/lotties/BadCat";
import { Lines } from "../components/Lines";
import { Grids } from "../components/Grids";

export const Footer = () => {
  const footerContentRef = useRef(null);

  const handleMouseMove = (e) => {
    const rect = footerContentRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    footerContentRef.current.style.setProperty("--x", `${x}px`);
    footerContentRef.current.style.setProperty("--y", `${y}px`);
  };

  useEffect(() => {
    const footerContent = footerContentRef.current;
    footerContent.addEventListener("mousemove", handleMouseMove);

    return () => {
      footerContent.removeEventListener("mousemove", handleMouseMove);
    };
  });

  const handleScroll = () => {
    const caseStudies = document.querySelector("#home");
    if (caseStudies) {
      caseStudies.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <section id="footer" className="section footer-section padding">
      <div className="footer-container">
        <div className="footer-content" ref={footerContentRef}>
          @UXKEO_
        </div>
        <div className="footer-copy">
          <a
            href="https://topmate.io/uxkeo"
            target="_blank"
            rel="noreferrer noopener"
            className="dm-me"
          >
            @DM_
          </a>
          <div>All Rights Reserved &copy; 2024 | UXKEO_</div>
          <a
            href="mailto:lunaticgemini2510@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
            className="mail-me"
          >
            @EMAIL_
          </a>
        </div>
        <div className="footer-arrow">
          <LottieButton
            Icon={<ArrowUp />}
            lottie={BadCat}
            onclick={handleScroll}
          />
        </div>
      </div>
      <Lines HLine={2} VLine={2} />
      <Grids />
    </section>
  );
};
